import * as React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSRProvider from 'react-bootstrap/SSRProvider';

import CancelOrder from '../../components/cancelOrder'
import DefaultHeader from '../../components/default-header'
import Footer from '../../components/footer'
import GdprPanel from '../../components/gdpr'
import Navigation from '../../components/navigation'
import Seo from '../../components/seo'
import NewsletterSignup from '../../components/newsletterSignUp';

const Page = () => {

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader headline="Thank You" heroBackground="url('https://web-cdn.meridianuniversity.edu/header-images/meridian-basic-forest-header-image-2023.webp')" heroMobileBackground="url('https://web-cdn.meridianuniversity.edu/header-images/meridian-basic-forest-header-image-mobile-2023.webp')" />
            <div id="body">
                <Container fluid="lg">
                    <Row>
                        <Col lg={12}>
                            <Row className="white-bg mb-5"> 
                                <Col className="p-4">
                                    <CancelOrder />  
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title="Cancel Order | Meridian University"
    />
)

export default Page